/* You can add global styles to this file, and also import other style files */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Noto Sans', sans-serif;
    color: #333333;
    overflow-x: hidden;
}

app-root {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #eeeeee;

    a {
        cursor: pointer;
        text-decoration: none;
    }
}

@media only screen and (max-width: 850px) {
    app-root {
        height: unset;
        min-height: 100%;
    }
}